import Vue from 'vue'
import App from './App.vue'
import router from './routes';
import './registerServiceWorker'
import axios from 'axios';
import Notifications from 'vue-notification';
import VueTheMask from 'vue-the-mask';
import VueSimpleAlert from "vue-simple-alert";
import Vuetify from 'vuetify'
import VueMaterial from 'vue-material'
import money from 'v-money'
// import '@fortawesome/fontawesome-free/css/all.css'
// import '@fortawesome/fontawesome-free/js/all.js'

import 'vuetify/dist/vuetify.min.css'
import 'vue-material/dist/vue-material.min.css'

Vue.use(money, { precision: 4 });
Vue.prototype.$user = JSON.parse(localStorage.getItem('user'));

const instance = axios.create({
    baseURL: "https://apiph6.infous.com.br/api"

    //baseURL: "http://127.0.0.1/www/infous/ph6/api/public/api"
    // baseURL: "http://127.0.0.1:8000/api"
});



let token = localStorage.getItem("api_token");
if (token) {
    instance.defaults.headers.common['Authorization'] = 'Bearer ' + token;
} else {
    console.log(token);
}


let options_kit = [
    { value: 0, text: `TODOS` },
    { value: 5, text: `R$ 30,00` },
    { value: 1, text: `R$ 35,00` },
    { value: 9, text: `RE RECH R$ 35,00` },
    { value: 10, text: `RE RECH R$ 40,00` },
    { value: 2, text: `R$ 40,00` },
    { value: 4, text: `R$ 50,00` },
    { value: 3, text: `R$ 60,00` },
    { value: 6, text: `R$ 70,00` },
    { value: 7, text: `Nacional R$ 50,00` },
    { value: 8, text: `R$ 60,00 Promoção` },

];

Vue.prototype.$options_kit = options_kit;

Vue.config.productionTip = false;
Vue.prototype.$http = instance;

Vue.component('Layout', () =>
    import ('./components/Layout.vue'));
Vue.component('Sidebar', () =>
    import ('./components/sidebar.vue'));
Vue.component('Footer', () =>
    import ('./components/footer.vue'));
Vue.component('Search', () =>
    import ('./components/search.vue'));
Vue.component('file-upload', () =>
    import ('./components/inputFileUploa.vue'));
Vue.component('image-upload', () =>
    import ('./components/inputImageUpload.vue'));
Vue.component('files-upload', () =>
    import ('./components/inputUploadMulti.vue'));
Vue.component('form-user', () =>
    import ('./components/formUser.vue'));
Vue.component('list-files', () =>
    import ('./components/listFiles.vue'));
Vue.component('value-kit', () =>
    import ('./components/valueKit.vue'));
Vue.component('sales-form', () =>
    import ('./components/salesForm.vue'));
Vue.component('kits-car-form', () =>
    import ('./components/kitsCarForm.vue'));
Vue.component('kits-card-form', () =>
    import ('./components/kitsCardForm.vue'));
Vue.component('notifications-list', () =>
    import ('./components/notifications.vue'));
Vue.component('card-user', () =>
    import ('./components/cardUser.vue'));
Vue.component('card-user-date', () =>
    import ('./components/cardUserDate.vue'));
Vue.component('list-sales-card', () =>
    import ('./components/listSalesCard.vue'));
Vue.component('list-birthdays', () =>
    import ('./components/listBirthdays.vue'));



Vue.component('avatar', () =>
    import ('./components/avatar.vue'));

Vue.component('product-form', () =>
    import ('./pages/products/form.vue'));


Vue.component('volume-form', () =>
    import ('./pages/volumes/form.vue'));

Vue.component('volume-input-form', () =>
    import ('./pages/volumes/form-input.vue'));

Vue.component('office-form', () =>
    import ('./pages/offices/form.vue'));

Vue.component('upload-file', () =>
    import ('./components/btnFileUpload.vue'));


Vue.use(VueTheMask);
Vue.use(Notifications);
Vue.use(VueMaterial)
Vue.use(VueSimpleAlert);

Vue.use(Vuetify)

const opts = {
    theme: {
        themes: {
            light: {
                primary: '#052740',
                secondary: '#D97904'
                // primary: '#9323C8',
                // secondary: '#39A4DC',
                // accent: '#8c9eff',
                // error: '#b71c1c',
            },
        },
    }
};

new Vue({
    router,
    render: h => h(App),
    vuetify: new Vuetify(opts)
}).$mount('#app')